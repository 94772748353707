<template>
  <div class="container">
    <el-card class="box-card" style="margin-bottom: 100px" v-loading="loading">
      <div slot="header" class="clearfix">
        <span>申请结果</span>
      </div>
      <ApplyPayLine  v-if="payApplyList.length" :payApplyList='payApplyList' :applyState='applyState' @hasSigned='hasSigned'></ApplyPayLine>
    </el-card>

    <div class="footer">
			<el-button style="width:240px;" @click="routerBack" >返回</el-button>
		</div> 
  </div>
</template>

<script>
import ApplyPayLine from '@/components/ApplyPayLine.vue'
import {
  freePayApplyInfo
} from '@/api/goods.js'
export default {
  components: {
    ApplyPayLine
  },
  data () {
    return {
      loading: false,
      payApplyList : [],
      applyState: null,
    }
  },
  created () {
    this.getData()
  },
  methods: {
    hasSigned() {
      this.getData()
    },
    routerBack() {
      let path = window.localStorage.getItem('toPayResultFromPath')
      this.$router.push(path)
    },
    async getData() {
      try {
        this.loading = true
        let result = await freePayApplyInfo()
        this.applyState = result.Result.ApplyState
        this.payApplyList = result.Result.RecordList
      } catch(e) {
      } finally {
        this.loading = false
      }
    },
  }
}
</script>

<style lang='less' scoped>
.container{
  .times-line {
    padding: 20px;
    background-color: #fff;
  }
}
.footer {
  box-shadow: 13px 1px 6px #999;
  line-height: 80px;
  background-color: #fff;
  position: fixed;
  bottom: 0px;
  width: 100%;
  text-align: center;
  z-index: 999;
}
</style>